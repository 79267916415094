@import 'styles/variable.less';

.workspace-main-content {
  width: 100%;
  height: 100%;
  .workspace-header {
    height: 56px;
    background-color: @darkGray;
    width: 100%;
    .flex-align-content-center();
    > div {
      width: 100%;
      .flex-align-content-center();
      padding: 0 96px;
      justify-content: space-between;
      .main-col {
        cursor: pointer;
        .step-title {
          padding-left: 8px;
          font-size: 14px;
          font-weight: 600;
          line-height: normal;
          text-shadow: none;
        }
      }
      .main-col-1 {
        min-width: 96px;
      }
      .main-col-2 {
        min-width: 138px;
      }
      .main-col-3 {
        min-width: 155px;
      }
      .empty-col {
        min-width: 20%;
        border: dashed 0.5px #95969d;
        stroke-dashoffset: 5px;
      }
    }
  }
}
.main-aoi-content {
  display: flex;
  justify-content: left;
  width: 100%;
  height: calc(100% - 56px);
  .left-content {
    .main-start-end-point {
      padding: 0 12px;
      .enter-point-main {
        .enter-point-title {
          font-size: 14px;
          font-weight: 600;
          line-height: 19.12px;
          text-shadow: none;
          margin-bottom: 6px;
          > span {
            color: @red;
          }
        }
        .point-form {
          .ant-form-item {
            margin-bottom: 6px;
            .ant-input-number {
              width: 100%;
              .ant-input-number-handler-wrap {
                display: none;
              }
            }
            .ant-btn-primary {
              margin-top: 8px;
              width: 100%;
              border-radius: 2px;
            }
            .point-a-btn {
              margin-bottom: 12px;
            }
          }
        }
      }
      .confirm-beeline {
        color: @primary;
        font-size: 10px;
        font-weight: 400;
        line-height: 13.66px;
        justify-content: flex-start;
        gap: 4px;
      }
    }
    .aoi-layers {
      height: calc(100% - 42px - 28px);
      .layer-collapse {
        background-color: unset;
        border: 0;
        height: 50%;
        .ant-collapse-item {
          height: 100%;
          border: 0;
          .ant-collapse-header {
            padding: 12px 0;
            cursor: default;
            .ant-collapse-expand-icon {
              .ant-collapse-arrow {
                right: 0;
              }
            }
            .ant-collapse-header-text {
              font-size: 14px;
              font-weight: 600;
              line-height: 16.39px;
              color: @titleColor;
            }
          }
          .ant-collapse-content {
            border: 0;
            height: calc(100% - 53px);
            overflow: auto;
            padding-right: 5px;
            .scrollbarW3();
            .ant-collapse-content-box {
              height: 100%;
              padding: 4px 0;
              padding-right: 2px;
              .layer-item-list {
                gap: 10px;
                .layer-list-item {
                  .flexJustifyBetween();
                  align-items: center;
                  > div {
                    .flexJustifyAlignCenter();
                    justify-content: space-between;
                    &:first-child {
                      .box-color {
                        width: 12px;
                        height: 12px;
                        border-radius: 2px;
                        margin-right: 8px;
                        margin-top: 2px;
                      }
                    }
                    &:last-child {
                      .layer-position {
                        border: 0.5px solid #c7c7cc;
                        border-radius: 2px;
                        font-size: 10.5px;
                        font-weight: 500;
                        line-height: 18px;
                        cursor: pointer;
                        color: #a9a9a9;
                        margin-right: 8px;
                        height: 18px;
                        max-width: 26px;
                        min-width: 18px;
                        text-align: center;
                        padding: 0 2px;
                      }
                      > svg {
                        cursor: pointer;
                      }
                      .route-eye {
                        margin-right: 8px;
                      }
                    }
                  }
                }
                .no-repo-file {
                  justify-content: center;
                  color: @red;
                }
              }
            }
          }
        }
        .ant-collapse-item-active {
          .ant-collapse-expand-icon {
            .ant-collapse-arrow {
              > span > button,
              > button {
                padding: 0;
                height: 16px;
                > span {
                  color: @primary;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
      .sticky-layer {
        .ant-collapse-item {
          .ant-collapse-content {
            overflow: hidden !important;
          }
        }
      }
      .aoi-main {
        height: 50%;
        .generate-aoi-btn-div {
          width: 100%;
          .generate-aoi-btn {
            width: 100%;
            border-width: 0.5px;
          }
          .skip-aoi-btn {
            > span {
              text-decoration: underline;
            }
          }
        }
        .aoi-list {
          height: calc(100% - 32px);
          padding-top: 12px;
          overflow: auto;
          .scrollbarW3();
          .generate-title {
            color: @titleColor;
            font-size: 14px;
            font-weight: 600;
            line-height: normal;
            text-shadow: none;
            > span {
              color: @red;
              padding-left: 3px;
              font-size: 14px;
              font-weight: 600;
            }
          }
          .aoi-list-items {
            margin-top: 6px;
            display: flex;
            flex-direction: column;
            height: calc(100% - 30px);
            overflow: auto;
            .scrollbarW3();
            padding-right: 5px;
            font-size: unset;
            .aoi-card {
              border-radius: 4px;
              margin: 4px 0;
              border-color: @borderColor;
              position: relative;
              min-height: 55px;
              cursor: pointer;
              .inr-content-col {
                border-radius: 4px 0px 0px 4px;
                height: 54px;
                position: absolute;
                width: 6px;
                top: 0;
                left: 0;
              }
              > div {
                padding: 5px;
                padding-left: 6px;
                padding-right: 0;
                .inner-card-content {
                  padding-left: 6px;
                  > div:first-child {
                    margin-right: 3px !important;
                    .list-aoi {
                      span:first-child {
                        top: 12px;
                        > span {
                          height: 13px;
                          width: 13px;
                          &::after {
                            top: 8px;
                            left: 8px;
                            width: 11px;
                            height: 11px;
                          }
                        }
                      }
                    }
                  }
                  .inner-card-content-col {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    > div {
                      .aoi-title {
                        font-size: 12px;
                        padding: 0;
                        color: @primary;
                        text-transform: uppercase;
                      }
                      span:last-child {
                        font-size: 10px;
                        color: @lightGray;
                        padding-left: 5px;
                      }
                    }
                    .upload-name {
                      font-size: 10px;
                      color: @lightGray;
                      > span {
                        color: @lightGray;
                        text-transform: uppercase;
                      }
                    }
                  }
                  .inner-card-content-icon {
                    display: flex;
                    align-items: center;
                    > div {
                      display: flex;
                      justify-content: flex-end;
                      align-items: flex-start;
                      width: 100%;
                      padding-top: 3px;
                      padding-right: 8px;
                      .aoi-retry {
                        color: @primary;
                        font-size: 12px;
                        font-weight: 600;
                        cursor: pointer;
                        padding: 0;
                        height: 26px;
                      }
                      .aoi-cancel {
                        width: 12px;
                      }
                      > svg {
                        cursor: pointer;
                      }
                    }
                  }

                  .remove-download {
                    > div {
                      justify-content: flex-end;
                      > div {
                        > a {
                          padding-right: 0;
                          > img {
                            width: 16px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .no-aoi-data-card {
              border-radius: 4px;
              position: relative;
              min-height: 54px;
              margin: 4px 0;
              .inr-content-col {
                border-radius: 4px 0px 0px 4px;
                height: 53px;
                position: absolute;
                width: 6px;
                top: 0;
                left: 0;
              }
              .ant-card-body {
                padding: 16px 5px !important;
                padding-bottom: 11px !important;
                .inner-card-content {
                  .inner-card-content-col {
                    padding-left: 10px;
                  }
                  .aoi-loader-icon {
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 12px;
                    .ant-progress-text {
                      font-size: 8px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .route-layers {
      height: 100%;
    }
    .workspace-final-btn {
      width: 100%;
      position: relative;
      padding: 0 12px;
      > button {
        width: 100%;
      }
      .fetch-layer-info {
        display: flex;
        flex-direction: column;
        width: 85%;
        position: absolute;
        top: -30px;
        left: 18px;
        font-size: 14px;
        font-weight: 500;
        line-height: 13.66px;
        background-color: @white;
        > div {
          .flexJustifyBetween();
          > span {
            color: #333333;
          }
        }
      }
      .complete-layer-info {
        position: absolute;
        top: -100px;
        left: 18px;
        height: 44px;
        border: 0.5px solid #2f80ed;
        background-color: #e6f1ff;
        width: 85%;
        border-radius: 2px;
        .flexJustifyBetween();
        align-items: flex-start;
        padding: 8px;
        > div {
          color: #2f80ed;
          font-size: 10px;
          font-weight: 400;
          line-height: normal;
          width: 85%;
        }
        > svg {
          margin-top: 2px;
          cursor: pointer;
        }
      }
      .aoi-satellite-info {
        color: @primary;
        font-size: 10px;
        font-weight: 400;
        text-align: center;
        padding-bottom: 6px;
      }
      .approve-decline-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > button {
          height: 30px;
          width: 115px;
          padding: 3px 15px;
        }
      }
    }
  }
  .workspace-left-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 24px;
  }
}
.raster-edit-div {
  gap: 5px;
}
.aoi-invalid-icon {
  display: flex;
  justify-content: space-between;
  // justify-content: space-around;
}
.approve-modal {
  z-index: 99;
  min-height: 177px;
  .ant-modal-header {
    .ant-modal-title {
      font-size: 20px;
      color: @titleColor;
    }
  }
  .ant-modal-body {
    padding: 0;
    .approve-modal-content {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      align-items: flex-start;
      justify-content: center;
      .modal-title {
        font-weight: 500;
        font-size: 16px;
        color: @titleColor;
        margin-bottom: 40px;
        margin-top: 16px;
        padding-left: 24px;
        padding-right: 20px;
      }
      .subdes-yes {
        font-size: 20px;
        padding-bottom: 20px;
        padding-left: 0;
        padding-top: 20px;
        margin: 0 auto;
      }
      .notify-content {
        font-weight: 500;
        font-size: 16px;
        color: @titleColor;
        padding-bottom: 45px;
        margin: 0 auto;
        padding: 12px;
        text-align: center;
      }
      .modal-content-btn {
        width: 100%;
        height: 60px;
        text-align: end;
        padding: 10px;
        padding-right: 16px;
        background-color: #f6f6f6;
        > button {
          height: 36px;
          padding: 3px 31px;
          box-shadow: none;
        }
        .confirm-btn {
          margin-right: 20px;
        }
        .cancel-btn {
          background-color: @ghostbtnBack;
          border-color: @ghostbtnBack;
          padding: 3px 26.5px;
          &:hover,
          &:focus,
          &:focus:hover,
          &::after {
            border: 0;
            outline: 0;
            border-color: @ghostbtnBack;
            box-shadow: none;
            background-color: @ghostbtnBack;
          }
        }
      }
      .no-cancel-btn {
        text-align: center;
        background-color: unset;
        padding-top: 5px;

        > button {
          margin-bottom: 20px;
        }
        .confirm-btn {
          margin-right: 0px;
        }
      }
    }
    .approved-aoi-sat-purchase {
      .modal-title {
        margin-bottom: 16px;
      }
      > span:nth-child(2) {
        padding-left: 24px;
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        color: @lightBlack;
      }
    }
    .sat-purchase {
      .modal-title {
        padding-left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 85px;
        padding: 0 24px;
        > div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f8f8f8;
          height: 100%;
          border-radius: 4px;
          gap: 10px;
          > div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
        .procurring {
          margin-left: 16px;
        }
      }
      > span:nth-child(2) {
        justify-content: center;
        flex-direction: row;
        width: 100%;
        padding-left: 0;
      }
    }
    .lulc-okay {
      .modal-title {
        > div {
          flex-direction: column !important;
          padding: 0 38px;
          .ant-progress-bg {
            height: 12px !important;
          }
        }
        .procurring {
          margin-left: 0px !important ;
        }
      }
    }
  }
}
.decline-modal {
  .modal-title {
    margin-bottom: 12px !important;
  }
}
.points-popup {
  background: @blackShade1;
  border-radius: 2px;
  margin-bottom: 0px;
  bottom: -9px !important;
  left: 16px !important;
  height: 23px;
  width: 70px;
  > div:first-child {
    background: unset;
    box-shadow: none;
    height: auto;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      margin: 0;
      gap: 10px;
      display: flex;
      align-items: center;
      > svg:first-child {
        margin: 3px 7px 3px 3px;
      }
      > svg:last-child {
        margin: 3px 7px 3px 27px;
      }
    }
  }
  > div:nth-child(2) {
    display: none;
  }
  > a {
    color: @white !important;
  }
}
.filter-dropdown {
  margin-top: 5px;
}

@primary-color: #316DB3;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;@btn-border-width: 0.5px;
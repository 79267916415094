@import 'styles/variable.less';

.repositoryForm {
  border: 1px solid @lightGray;
  border-radius: 3px;
  padding: 15px 35px;
  padding-bottom: 0;

  .uplodeFile {
    > div {
      > div {
        label {
          > span {
            font-size: 18px;
            color: @primary;
            font-weight: 500;
            > span {
              color: @red;
            }
          }
        }
      }
    }
    .uplodeFileBtn {
      width: 190px;
      @media (max-width: 1024px) {
        width: 100%;
      }
    }
    .file-input {
      border: 1px solid @avatarTitle;
      &:hover {
        border: 1px solid @avatarTitle;
      }
      > input {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: @primary;
      }
    }
    .selectCategory {
      .ant-select-selector {
        border-color: @avatarTitle;
        border-radius: 2px;
      }

      .ant-select-selection-placeholder {
        color: #717171 !important;
      }
    }
    .uplodeFileBtn,
    .innerBufferContent {
      border-radius: 0;
      border: 1px solid @avatarTitle;
      &:hover {
        border: 1px solid @avatarTitle;
      }
      > input {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: @primary;
      }
    }

    .addCommentsInput {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: @primary;
      padding: 9px 11px;
      border: 0.5px solid #717171;
      &:focus {
        box-shadow: none;
      }
      &::placeholder {
        color: black !important;
      }
    }
  }
  .uplodeBtnCol {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .ant-form-item {
      margin: 0;
    }
    .submit-button {
      margin-top: 12px;
      padding: 7px 40px;
      width: 140px;
    }
  }
}
.ant-select-selection-item {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: @primary;
}
.file-weight-div {
  border: 1px solid @avatarTitle;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  height: 40px;
  > span:first-child {
    width: 50px;
    border-right: 0.1px solid @borderColor;
    text-align: center;
    font-size: 16px;
    color: #00000099;
  }
  .weights-slider {
    .flexAlignCenter();
    justify-content: space-evenly;
    width: calc(100% - 50px);
    margin: 0;
    > span:first-child,
    > span:last-child {
      font-size: 14px;
      font-weight: 400;
      line-height: 20.02px;
      letter-spacing: 0.17px;
      text-align: center;
      color: #00000099;
    }
    .ant-slider {
      margin: 0;
      width: 40%;
      .ant-slider-handle {
        background-color: @primary;
        border-width: 1px;
        border-color: @primary;
      }
      .ant-slider-track {
        background-color: @primary;
      }
      .ant-slider-step {
        display: none;
      }
    }
  }
}
.innerBufferContent {
  > div {
    > div:last-child {
      .ant-input {
        font-weight: 500;
        font-size: 16px;
        color: @primary;
      }
      .ant-input,
      .ant-input-group-addon {
        border: 1px solid @avatarTitle !important;
      }
      .ant-input-group-addon {
        border-left-width: 0px !important;
      }
    }
  }
}

@primary-color: #316DB3;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;@btn-border-width: 0.5px;
@import 'styles/variable.less';

.resume-route-gen {
  width: 523px !important;
  .ant-modal-header {
    padding-bottom: 9px;
  }
  .ant-modal-body {
    padding-top: 20px;
    padding-bottom: 28px;
  }
  .ant-modal-footer {
    direction: rtl;
    background-color: #f6f6f6 !important;
    > button:first-child {
      width: 100px;
      height: 36px;
    }
    > button:last-child {
      width: 115px;
      height: 36px;
      margin-left: 0;
      margin-right: 25px;
    }
  }
}

@primary-color: #316DB3;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;@btn-border-width: 0.5px;
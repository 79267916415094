@import 'styles/variable.less';

.range-slider {
  background-color: @white;
  border: 0.1px solid #717171;
  border-radius: 2px;
  padding: 6px 0px;
  .flexAlignCenter();
  width: 360px;
  height: 36px;
  box-shadow: 0px 4px 4px 0px #00000040;
  > span {
    width: 35px;
    border-right: 0.1px solid #717171;
    text-align: center;
    color: @primary;
  }
  .slider-div {
    width: calc(100% - 35px);
    .flexAlignCenter();
    justify-content: space-evenly;
    > span:first-child,
    > span:last-child {
      font-size: 14px;
      font-weight: 400;
      line-height: 20.02px;
      letter-spacing: 0.17px;
      text-align: center;
      color: #00000099;
    }
    .slider-close-btn {
      height: 30px;
      width: 30px;
      .flexAlignCenter();
      justify-content: center;
    }
    .ant-slider {
      margin: 0;
      width: 40%;
      .ant-slider-handle {
        background-color: @primary;
        border-width: 1px;
        border-color: @primary;
        height: 12px;
        width: 12px;
        margin-top: -4px;
      }
      .ant-slider-track {
        background-color: @primary;
      }
      .ant-slider-step {
        display: none;
      }
    }
  }
}
.layer-pos-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-arrow {
      .ant-tooltip-arrow-content {
        --antd-arrow-background-color: @white;
        box-shadow: 0px 2px 4px 0px #00000026;
      }
    }
    .ant-tooltip-inner {
      min-height: 24px;
      min-width: 82px;
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      background-color: @white;
      box-shadow: 0px 2px 4px 0px #00000026;
      color: @titleColor;
    }
  }
}
.layer-weight-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-arrow {
      display: none;
    }
    .ant-tooltip-inner {
      position: absolute;
      left: -48px;
      bottom: -42px;
      background-color: unset;
      box-shadow: none;
    }
  }
}

@primary-color: #316DB3;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;@btn-border-width: 0.5px;
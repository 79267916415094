@import '/src/styles/variable.less';

.aoi_details_card_container {
  position: absolute;
  z-index: 9999;
  bottom: 0;
  width: 100%;
  padding: 0 16px;
  &.hide_modal {
    height: 4px;
  }
  .aoiDetailsCard_Sections {
    position: relative;
    border-radius: 4px;
    background: @white;

    .arrow_icon_sections {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -12px;
      left: 50%;
      transform: translate(0%, -50%);
      width: 45px;
      height: 25px;
      background: @white;
      border-radius: 50% 50% 0 0/100% 100% 0 0;
      cursor: pointer;
    }
    .hide_modal {
      height: 35px;
      overflow: hidden;
    }
    .open_modal {
      height: 42vh;
    }

    .divider {
      border-bottom: 1px solid @borderColor;
      margin: 16px 25px;
      width: calc(100% - 46px);
    }
    .route-modal {
      transition: height 0.2s ease-in-out;
      overflow: hidden;
    }

    .route-detail-data {
      .hard_cost {
        display: flex;
        padding: 12px;

        h2 {
          color: @greyShed;
          font-size: 14px;
          font-weight: 600;
          padding: 5px 14px 0px 5px;
        }
      }
    }
    .header-sections {
      align-items: center;
      justify-content: space-between;
      padding: 8px 12px;
      border-bottom: 1px solid #c7c7cc;

      .col-custom {
        // white-space: nowrap;
        text-align: left;
        button {
          display: flex;
          width: 98px;
          padding: 3.5px 0px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 2px;
          border: 0.5px solid @primary;
          background: @primary;
          color: @white;
        }
      }
      .close-btn {
        .flexAlignCenter();
        justify-content: flex-end;
      }
      > div:nth-child(2) {
        // text-align: end;
      }
      > div {
        .detail-title,
        .detail-title-data {
          font-size: 14px;
          font-weight: 600;
          line-height: normal;
        }
        .detail-title {
          color: @titleColor;
        }
        .detail-title-data {
          color: @primary;
        }
      }
    }
    .aoi-data-details {
      > div:nth-child(2) {
        text-align: center;
      }
      > div:nth-child(3) {
        text-align: end;
      }
    }
    .detail-main-header {
      height: calc(100vh - 38%);
      overflow-x: hidden;
      overflow-y: auto;
      .total_route_cost {
        padding: 17px;

        h1 {
          color: @greyShed;
          font-size: 14px;
          font-weight: 600;
        }

        .routes_table_data {
          border-radius: 4px;
          border: 1px solid #eee;
          th {
            text-align: center !important;
          }
          td {
            text-align: center !important;
            border-right: 1px solid #f0f0f0;
          }
        }
      }
      .steel-con-cost {
        padding: 0px 16px;
        gap: 8rem;
        white-space: nowrap;
        > div:first-child {
          display: flex;
          align-items: center;
        }
        > div:last-child {
          border-left: 0.5px solid @borderColor;
          padding-left: 50px;

          > span {
            padding-right: 30px;
          }
        }
        > div {
          .ant-input-number {
            width: 45px;
            .ant-input-number-handler-wrap {
              display: none;
            }
            .ant-input-number-input {
              color: @primary;
              font-size: 12px;
              font-weight: 500;
              line-height: normal;
            }
          }
        }

        .input-number {
          width: 30% !important;
        }
      }
      .body_sections {
        .equal-height {
          display: flex;
        }

        /* Ensure the Col elements stretch to equal height */
        .equal-height .ant-col {
          display: flex;
          flex-direction: column;
        }

        /* Ensure the child components stretch to fill the height */
        .equal-height .ant-col > * {
          flex: 1;
        }
        > div {
          > div {
            .aoi_card {
              padding: 12px;
              border: 0.5px solid #c7c7cc;
              border-radius: 4px;
              background: @white;
              .aoi_card_title {
                color: @greyShed;
                font-size: 14px;
                font-weight: 600;
                line-height: normal;
              }
            }
          }
        }
      }
      .tower_sections,
      .total_cost_details {
        padding: 16px;
        .tower-detail-content {
          .tower-table {
            .ant-table-container {
              .ant-table-header {
                > table {
                  > thead {
                    > tr {
                      > th {
                        border: 0.5px solid #eeeeee;
                        padding: 8px !important;
                        background-color: #eeeeee;
                        text-align: center;
                        color: @typocolor;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: normal;
                        &::before {
                          display: none;
                        }
                        &:last-child {
                          border-left: 0;
                        }
                      }
                    }
                  }
                }
              }
              .ant-table-body {
                .scrollbarW3();
                > table {
                  > tbody {
                    > tr {
                      &:last-child {
                        td {
                          &:not(:last-child) {
                            border-right: 0px solid !important;
                            border-left: 0px solid !important;
                          }
                          &:first-child {
                            border-left: 0.5px solid #eeeeee !important;
                          }
                        }
                      }
                      > td {
                        background-color: @white;
                        border: 0.5px solid #eeeeee !important;
                        text-align: center;
                        color: @typocolor;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: normal;
                        &:first-child::before {
                          display: none;
                        }
                        &:nth-child(5) {
                          font-size: 12px;
                        }
                      }
                    }
                  }
                  > tfoot {
                    > tr {
                      > td {
                        padding: 12px;
                        font-size: 12px;
                        font-weight: 500;
                        border-left: 0.5px solid #eeeeee;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .total-row-cost {
        padding-top: 0;
        .ant-table-body {
          > table {
            > tbody {
              > tr {
                > td {
                  &:nth-child(4) {
                    font-size: 12px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.aoi-card-table {
  padding-top: 8px;
  .ant-table-container {
    .ant-table-header {
      > table {
        > thead {
          > tr {
            > th {
              padding: 5px !important;
              background-color: @white;
              &:first-child::before {
                display: none;
              }
              &:nth-child(2),
              &:nth-child(3) {
                text-align: center;
              }
            }
          }
        }
      }
    }
    .ant-table-body {
      .scrollbarW3();
      > table {
        > tbody {
          > tr {
            > td {
              padding: 5px !important;
              background-color: @white;
              border: 0;
              &:first-child::before {
                display: none;
              }
              &:nth-child(2),
              &:nth-child(3) {
                text-align: center;
              }
            }
            .table-row-data {
              color: @greyShed;
              font-size: 12px;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 4px 12px;
  height: 23px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 0.5px solid @primary;
  border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 0.5px solid @primary;
}
.editable-row,
.editable-cell-value-wrap {
  border: 0.5px solid @primary;
}
.aoi_card {
  padding: 12px;
  margin: 16px;
  border: 0.5px solid #c7c7cc;
  border-radius: 4px;
  background: @white;
  height: 300px;
  .aoi_card_title {
    color: @greyShed;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
  }
}

@primary-color: #316DB3;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;@btn-border-width: 0.5px;
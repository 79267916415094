@import 'styles/variable.less';

.workspace-map-container {
  width: 100%;
  height: 100%;
  position: relative;
  .map-controls-section {
    position: absolute;
    top: 40%;
    right: 8px;
    display: flex;
    flex-direction: column;
    .map-control-btn {
      background-color: rgba(37, 37, 37, 0.75);
      border: 0;
      box-shadow: none;
      height: 28px;
      width: 28px;
      vertical-align: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      &:hover {
        box-shadow: none;
        border: 0;
        background-color: rgba(37, 37, 37, 0.75);
      }
      &:focus {
        box-shadow: none;
        border: 0;
        background-color: rgba(37, 37, 37, 0.75);
      }
    }
    .plus-bth,
    .minus-btn,
    .move-icon,
    .polygon-icon,
    .map-icon {
      margin-top: 5.6px;
    }
    .map-layer {
      margin-top: 5.6px;
      background-color: unset;
      &:hover {
        background-color: unset;
      }
    }
  }
}
.project-mapview-container {
  .map-controls-section {
    right: 32px;
  }
}
.popup-content {
  background-color: #252525f2;
  margin-top: 10px;
}
.aoi-content-row {
  width: calc(100% - 222px);
  position: relative;
  .bee-line-distance {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 999;
    width: 231px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    padding: 10px;
    .finalized-aoi,
    .bee-line-aoi {
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        width: 12px;
        border-radius: 2px;
        margin-right: 6px;
      }
    }
    .finalized-aoi {
      > div {
        height: 12px;
      }
    }
    .bee-line-aoi {
      padding-top: 3px;
      > div {
        height: 3px;
      }
    }
  }
  .go-nogo-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    > span {
      font-size: 12px;
      color: @black;
    }
  }
}
.edit-mode,
.save-mode {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 17px;
  position: absolute;
  top: 12px;
  left: 38%;
  z-index: 999;
  border-radius: 4px;
  opacity: 0.95;
  background-color: @white;
  width: 349px;
  height: 44px;
  > span {
    color: @blackShade3;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
  }
  .save-aoi-btn {
    background-color: @syntaxBlue;
    border: 1px solid @white;
    color: @white;
  }
  .save-aoi-btn:disabled {
    background-color: #f5f5f5;
    color: @black;
  }

  > button {
    color: @syntaxBlue;
    border: 1px solid @syntaxBlue;
    border-radius: 4px;
    display: flex;
    width: 89px;
    height: 28px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
  }
}
.draw-mode-route {
  height: 25px;
  padding-left: 9px;
  padding-right: 0;
  > div:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    > div {
      color: #030303;
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
    }
    > button {
      width: 18px;
      height: 20px;
      padding: 0;
    }
  }
  > div:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: inherit;
    > button:first-child {
      width: 51px;
      height: 16px;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      padding: 0;
      text-align: center;
    }
  }
}
.save-mode {
  background-color: @primary;
  top: 52px;
  height: 32px;
  cursor: pointer;
}
.active-ruler {
  background-color: @white !important;
  &:hover {
    background-color: @white !important;
  }
  &:focus {
    background-color: @white !important;
  }
}
.spin-loader {
  animation: rotateScale 1s linear infinite;
}

.route-point {
  width: 200px;
  background-color: @white;
  .route-ol-container {
    .flexJustifyBetween();
    align-items: center;
    > div:first-child {
      .flexDirectionColumn();
      > span {
        font-size: 12px;
      }
    }
    > button {
      .flexJustifyAlignCenter();
      background-color: unset;
      border: 0;
      cursor: pointer;
    }
    #closebutton,
    #routeclosebutton {
      font-size: 16px;
    }
  }
}
.edit-route-modal {
  .ant-modal-body {
    padding: 12px;
  }
  .edit-tower-container {
    .flex-align-content-center();
    justify-content: space-evenly;
    .point-title {
      color: @typocolor;
      font-size: 12px;
      font-weight: 600;
      line-height: normal;
    }
    .deviation-angle {
      border: 0.5px solid;
      width: 100px;
      height: 36px;
      padding: 10px 15px;
      color: @lightBlack;
      border-radius: 2px;
      border: 1px solid @lightWhite2;
      margin-top: 9px;
    }
    .tower-type {
      width: 100px;
      height: 36px;
      margin-top: 9px;
      .ant-select-selector {
        height: 100%;
      }
    }
  }
}
.spinner {
  height: calc(100vh - 120px);
  z-index: 99999;
  top: 0%;
  .ant-spin-dot {
    top: 48%;
  }
}
.approved-aoi-route {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: @white;
  min-width: 172px;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  justify-content: space-between;
  > div:first-child {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    .box-color {
      margin-top: 6px;
      height: 12px;
      width: 12px;
      border-radius: 2px;
    }
    > div:last-child {
      display: flex;
      flex-direction: column;
    }
  }
  > div:last-child {
    > svg {
      cursor: pointer;
    }
  }
}
.line-new-point-popup {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: @white;
  width: 201px;
  min-height: 108px;
  border-radius: 4px;
  .flexDirectionColumn();
  gap: 12px;
  .modal-title-new-point {
    padding: 0 8px;
    padding-top: 8px;
    padding-right: 10px;
    .flexJustifyBetween();
    align-items: center;
    > div:first-child {
      .flexJustifyBetween();
      align-items: center;
      gap: 3px;
      .tower-name {
        color: @greyShed;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
      }
      .tower-coord {
        color: #95969d;
        font-size: 10px;
        font-weight: 500;
        line-height: 18px;
      }
    }
    > svg:last-child {
      cursor: pointer;
    }
  }
  > div:nth-child(2) {
    padding: 0 8px;
    .flexAlignCenter();
    gap: 24px;
    > div {
      .flexAlignCenter();
      gap: 12px;
      .tower-type {
        width: 70px;
        height: 30px;
        .ant-select-selector {
          height: 100%;
          padding: 0 8px;
          .ant-select-selection-item {
            font-size: 12px;
          }
          .ant-select-selection-search-input {
            height: 22px;
          }
        }
      }
    }
  }
  .line-new-btns {
    border-top: 0.5px solid @borderColor;
    padding: 0 8px;
    min-height: 34px;
    .flexAlignCenter();
    justify-content: flex-end;
    > button span {
      text-decoration: underline;
    }
  }
  .delete-icon-div {
    justify-content: space-between !important;
    > div {
      display: flex;
      align-items: center;
    }
    .delete-btn {
      width: 24px;
      height: 24px;
    }
  }
  .confirm-btn {
    padding-right: 2px;
  }
}
.line-gnt-point-popup {
  min-height: auto;

  .gnt-confirm-add-end-btn {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 5px;
    > div:first-child {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 3px;
      > button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        color: #95969d;
        > span {
          text-decoration: none !important;
        }
        &:last-child {
          padding: 0;
        }
      }
      .delete-btn {
        padding: 0;
      }
      .add-new-tower {
        padding-left: 3px !important;
        padding-right: 5px !important;
        > span {
          text-decoration: none !important;
        }
      }
      .resume-btn {
        padding-left: 0 !important;
      }
    }
  }
}
.tower-type-select-popup {
  .ant-select-item-option {
    padding: 7px;
    justify-content: center;
    .ant-select-item-option-content {
      text-align: center;
      font-size: 12px;
    }
  }
}

@primary-color: #316DB3;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;@btn-border-width: 0.5px;
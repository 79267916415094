@import 'styles/variable.less';

.main-project-content {
  background-color: @white;
  .main-title-content {
    position: relative;
    .ant-page-header-heading {
      .ant-page-header-heading-left,
      .ant-page-header-heading-title {
        overflow: unset;
      }
      .ant-page-header-heading-title {
        width: 380px;
        display: flex;
        gap: 18px;
        > input {
          width: 268px;
        }
      }
    }
    > div {
      > div {
        > div {
          margin-right: 10px;
          font-size: 8px;
        }
        > span {
          color: @titleColor;
        }
      }
    }
  }
  .custom-toggle-button {
    width: 70px;
    height: 30px;
    border: 1px solid @primary;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    > img {
      width: 18px;
      height: 12px;
    }
    > .custom-toggle-wrapper {
      width: 100%;
      height: 29px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > .custom-toggle-wrapper.active {
      width: 100%;
      height: 29px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: @primary;
    }
  }
  .topContentBtn {
    background-color: @white;
    color: @primary;
    box-shadow: none;
    &:hover {
      background-color: @primary;
      color: @white;
    }
    > div {
      > div {
        > div {
          span {
            color: @titleColor;
            text-shadow: none;
            font-weight: 500;
          }
        }
      }
    }
  }
}
.descriptionText {
  color: @notification;
  font-weight: 500;
  font-size: 24px;
}
.maintableContent {
  border-radius: 4px;
  height: calc(100vh - 160px);
  position: relative;
  margin: 0 20px 20px;
  > header {
    display: none;
  }
  > div:nth-child(2) {
    border: 1px solid @primary;
    max-height: 100% !important;
    height: 100% !important;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: @veryLightGray;
      border-radius: 3px;
    }
    > div {
      height: 100% !important;
      > div {
        height: 100% !important;
        > div:first-child {
          background-color: @darkGray;
          z-index: 2;
          @media (max-width: 1441px) {
            padding: 0px;
          }
          > div {
            background-color: transparent;
            border: 0;
            > div {
              padding-left: 0px;
              padding-right: 0px;

              > div {
                justify-content: left;
                padding-left: 20px;
                font-size: 14px;
                font-weight: bold;
                color: @btnTitle;
                text-shadow: none;
                .mergedTitleText {
                  display: flex;
                  justify-content: space-evenly;
                  white-space: nowrap;
                  width: inherit;
                }
                &:hover {
                  color: @btnTitle;
                  text-shadow: none;
                  opacity: 1;
                }
                svg {
                  opacity: 1;
                  fill: @btnTitle;
                  transform: rotate(0deg);

                  &:hover {
                    opacity: 1;
                  }
                }
                span {
                  text-shadow: none;
                  color: @btnTitle;
                  padding-left: 5px;
                  display: flex;
                  justify-content: left;
                  padding-left: 10px;
                  &:hover {
                    color: @btnTitle;
                    text-shadow: none;
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
        > div:nth-child(2) {
          display: block;
          padding: 0 0 35px 0;
          @media (max-width: 1441px) {
            padding: 0 0 35px 0;
          }
          > div {
            background: @white;
            border-bottom: 1px solid @borderColor;
            border-radius: 2px;
            height: 75px;
            > div {
              justify-content: left;
              padding-left: 20px;
              cursor: pointer;

              .editAccess {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: @avatarTitle;
              }
              .start-end-point {
                .point-name {
                  display: block;
                  font-weight: 500;
                  font-size: 14px;
                  color: @avatarTitle;
                }
                .point-coord {
                  display: block;
                }
              }
              .last-updated {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                .project-dateTime {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 17px;
                  color: @avatarTitle;
                }
              }
              .avatarGrpContent {
                > span {
                  background-color: @white;
                  border: 1px solid @avatarTitle;
                  color: @avatarTitle;
                  cursor: pointer;
                  &:hover {
                    background-color: @avatarTitle;
                    color: @white;
                  }
                }
              }
            }
            > div {
              &:first-child {
                justify-content: center;
                padding-right: 70px;
              }
              &:last-child {
                justify-content: left;
                padding-left: 20px;
              }
              &:nth-last-child(2) {
                justify-content: center;
                padding-right: 70px;
              }
              .mergedCell {
                width: 100%;
                display: flex;
                justify-content: space-evenly;

                .mergedCellError {
                  color: red;
                }
              }
              &:nth-child(4) {
                padding: 0px 30px;
              }
              &:nth-child(3),
              &:nth-child(4) {
                > div {
                  > span:nth-child(2) {
                    padding-top: 7px;
                    @media (max-width: 1441px) {
                      padding-top: 5px;
                    }
                  }
                  > span:last-child,
                  > span:nth-child(2) {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .mainProjectTLFooter {
    height: 34px;
    background: @darkGray;
    border-radius: 0px 0px 4px 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    position: absolute;
    left: 0;
    bottom: 1px;
    width: 99.85%;
    margin: 0 1px;
    z-index: 2;
    @media (max-width: 1300px) {
      gap: 7px;
      width: 99.8%;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      span:first-child {
        padding: 0 10px;
        color: @lightGreyShade1;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        @media (max-width: 1200px) {
          padding: 0 5px;
          font-size: 11px;
        }
      }
      span:last-child {
        padding: 5px 7px;
        background-color: @verylightBlack;
        background: @lightWhite4;
        border-radius: 2px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        @media (max-width: 1200px) {
          padding: 5px;
          font-size: 11px;
        }
      }
    }
    .companyProjectContent {
      padding-left: 10px;
      > span:first-child {
        color: @primary;
      }

      > span:last-child {
        margin-right: 8px;
      }
    }
    .notStartedContent,
    .inProgressContent,
    .completeContent,
    .closeContent {
      &:before {
        content: '';
        background-color: @red;
        height: 7px;
        width: 7px;
        display: inline-block;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
      }
    }
    .inProgressContent {
      &:before {
        background-color: @orange;
      }
    }
    .completeContent {
      &::before {
        background-color: @green;
      }
    }
    .closeContent {
      &::before {
        background-color: @primary;
      }
    }
  }
  .statusFilterIcon {
    margin-left: 6px;
  }
}
.filter-input {
  > header {
    position: absolute;
    top: -62px;
    left: 84%;
    width: 16%;
    padding: 0;
    height: 40px;
    @media (max-width: 1700px) {
      left: 80%;
      width: 20%;
    }
    @media (max-width: 1441px) {
      width: 20%;
    }
    @media (max-width: 1240px) {
      left: 77%;
      width: 23%;
    }
  }
}
.tootltipMember {
  > div {
    > div {
      color: @black;
    }
  }
}
.assign-project-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.assignRecord {
  color: @avatarTitle;
  display: block !important;
  margin: 5px;
  font-weight: 500;
  font-size: 14px;
}
.hideBtn {
  display: none;
}
.verticle-line {
  border-left: 4px solid @greyShade5;
  background: @greyShade5;
  border-radius: 2px;
  height: 28px;
  margin-left: 5px;
}
.manager-propject {
  padding-left: 0 !important;
}

.manager-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 35px;
  height: 35px;
  background-color: #fff;
  color: #000000;
  border: 0.5px solid #727272;
}

.list-map-grp-btn {
  > button:first-child {
    border-radius: 4px 0px 0px 4px;
  }
  > button:last-child {
    border-radius: 0px 4px 4px 0px;
  }
  .active-view-btn {
    background-color: @primary;
    border-color: @primary;
    border-radius: 4px;
  }
}

@primary-color: #316DB3;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;@btn-border-width: 0.5px;
@import 'styles/variable.less';

.route-card {
  background: @white;
  border-radius: 4px;
  line-height: unset;
  margin: 4px 0;
  min-height: 55px;
  .ant-card-body {
    padding: 4px;
    padding-right: 0;
    .inr-content-col {
      border-radius: 4px 0px 0px 4px;
      height: 54px;
      position: absolute;
      width: 6px;
      top: 0;
      left: 0;
    }
    .inner-card-content {
      display: flex;
      justify-content: space-around;
      width: 100%;
      cursor: pointer;
      .inner-card-content-col {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 76%;
        padding-left: 4px;
        > div {
          span:first-child {
            font-size: 12px;
            padding: 0;
            color: @primary;
          }
          span:last-child {
            font-size: 10px;
            color: @lightGray;
          }
        }
        .upload-name {
          font-size: 10px;
          color: @lightGray;
          line-height: 12px;
          > span {
            color: @lightGray;
            text-transform: uppercase;
          }
        }
      }
      .inner-card-content-icon {
        display: flex;
        align-items: center;
        text-align: center;
        > div {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          padding-right: 8px;
          > svg,
          > span {
            cursor: pointer;
          }
        }
      }
      .remove-download {
        > div {
          justify-content: flex-end;
          padding-right: 6px;
        }
      }
    }
  }
}

@primary-color: #316DB3;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;@btn-border-width: 0.5px;
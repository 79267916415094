@import 'styles/variable.less';

.main-analytics {
  width: 100%;
  // height: 100%;
  height: calc(100vh - 60px);
  background-color: @white;
  padding: 15px;

  .main-tab-content {
    height: 100%;

    > div:first-child {
      &:before {
        display: none;
      }
    }

    .no_data {
      display: flex;
      justify-content: center;
    }

    .ant-tabs-top {
      .ant-tabs-nav {
        margin: 0;
      }
    }

    .ant-tabs-content-holder {
      .ant-tabs-content-top {
        height: 100%;
      }
    }

    > div {
      > div {
        > div {
          background: @analyticBack;
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
          border-radius: 2px;

          > div:first-child,
          > div:nth-child(2) {
            > div:nth-child(1) {
              > div {
                > div:nth-child(1) {
                  background-color: @lightdarkGray;
                  font-weight: 600;
                  font-size: 14px;
                  color: @titleColor;
                }

                > div:nth-child(2) {
                  background-color: @lightdarkGray2;
                  font-weight: 500;
                  font-size: 14px;
                  color: @greyShed;
                }

                > div:nth-child(3) {
                  background-color: @lightdarkGray2;
                  font-weight: 500;
                  font-size: 14px;
                  color: @greyShed;
                }
              }
            }
            .aoiTabScrollable {
              max-height: calc(100vh - 247px) !important;
            }
            > div:nth-child(2) {
              max-height: calc(100vh - 600px);
              overflow: auto;
              scrollbar-width: none;
            }
            > div {
              display: flex;
              flex-direction: row;

              > div:first-child {
                color: @typocolor;
                font-weight: 500;

                .analyticSubTitleName {
                  color: @greyShed;
                  font-size: 14px;
                  font-weight: 600;
                }

                .highlighted {
                  background: #ebebeb;
                }
              }
              > div {
                display: flex;
                justify-content: center;
                flex-direction: column;
                width: 25%;
                height: fit-content;

                > div {
                  padding: 10px 12px;
                  border: 1px solid @borderColor3;

                  color: @greyShed;
                  display: flex;
                  justify-content: center;
                  white-space: nowrap;
                }

                .highlighted {
                  background: #ebebeb;
                }
              }
              .tab-title-inr {
                font-weight: 600;
                font-size: 12px;
                color: @analyticTitle;
                text-shadow: none;
                letter-spacing: 0.5px;
                text-transform: uppercase;
              }
            }

            padding: 5px 9px 5px 7px;
          }

          .ant-tabs-tab {
            padding: 7px 28px !important;
          }

          .ant-tabs-tab-active {
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
            border-radius: 2px;

            > div {
              .tab-title-inr {
                color: @primary !important;
                text-shadow: none;
                letter-spacing: 0.5px;
              }
            }
          }

          > div {
            &:nth-child(2) {
              margin-left: 5px;
              padding-left: 21px;
              padding-right: 21px;
            }
          }
        }
      }
    }

    .feature-tab,
    .cost-tab {
      height: 100%;
      border: 1px solid @primary;
      border-radius: 2px;
      background-color: @white;
    }

    .feature-tab {
      .compareFixedTable {
        height: 100%;

        > div {
          height: 100%;

          > div {
            height: 100%;

            > div {
              height: 100%;

              > div {
                height: 100%;

                > div {
                  &::-webkit-scrollbar {
                    width: 5px;
                  }

                  &::-webkit-scrollbar-thumb {
                    background: @veryLightGray;
                    border-radius: 3px;
                  }

                  > table {
                    > thead {
                      background: @lightdarkGray;
                      height: 41px;

                      > tr {
                        > th {
                          font-weight: 500;
                          font-size: 14px;
                          color: @roottableth;

                          &:before {
                            display: none;
                          }
                        }
                      }
                    }

                    > tbody {
                      tr {
                        > td {
                          font-weight: 500;
                          font-size: 14px;
                          padding: 7px 16px;
                          background-color: @lightdarkGray2;
                        }
                      }

                      .empty-row {
                        display: none !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .no-feature-data {
        > div {
          > div {
            > div {
              > div {
                > div {
                  > table {
                    > tbody {
                      tr {
                        &:nth-child(2) {
                          background-color: @white !important;
                        }

                        > td {
                          border: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .cost-tab {
      .compareFixedTable {
        height: 100%;

        > div {
          height: 100%;

          > div {
            height: 100%;

            > div {
              height: 100%;

              > div {
                height: 100%;

                > div {
                  height: 100%;
                  overflow: auto;

                  &::-webkit-scrollbar {
                    width: 5px;
                  }

                  &::-webkit-scrollbar-thumb {
                    background: @veryLightGray;
                    border-radius: 3px;
                  }

                  > table {
                    text-align: center;
                    table-layout: fixed;
                    width: 100%;

                    > thead {
                      background: @lightdarkGray;
                      height: 41px;

                      > tr {
                        > th {
                          font-weight: 500;
                          font-size: 14px;
                          color: @roottableth;
                          border-right: 0.1rem solid @borderColor3;
                          text-align: center;

                          &:before {
                            display: none;
                          }
                        }

                        .compareRoutes {
                          font-weight: 600;
                        }
                      }
                    }

                    > tbody {
                      tr {
                        > td {
                          font-weight: 500;
                          font-size: 14px;
                          padding: 7px 16px;
                          border-right: 0.1rem solid @borderColor3;
                          word-wrap: break-word;
                        }
                      }

                      tr:nth-child(1),
                      tr:nth-child(2),
                      tr:nth-child(5),
                      tr:nth-child(13),
                      tr:last-child {
                        > td {
                          background-color: @lightdarkGray2;
                          border-right: 0.1rem dashed @lightdarkGray2;
                        }
                      }

                      .empty-row {
                        display: none !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .no-feature-data {
        > div {
          > div {
            > div {
              > div {
                > div {
                  > table {
                    > tbody {
                      tr {
                        &:nth-child(2) {
                          background-color: @white !important;
                        }

                        > td {
                          border: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.upload-csv {
  position: absolute;
  right: 17px;
  top: 79px;
  padding: 4px 34px !important;
  border: 1px solid @primary;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 8px;
  background: @primary;
  color: #fff;

  > div {
    > span {
      font-weight: 500;
      color: @primary;
    }
  }

  &:disabled {
    > span {
      color: @avatarTitle;
    }

    &:hover {
      > span {
        color: @avatarTitle;
      }
    }
  }

  &:hover {
    background-color: @primary;
    transition: 0.3s;

    > span {
      color: @white;
    }
  }
}

@primary-color: #316DB3;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;@btn-border-width: 0.5px;
@primary: #316db3;
@secondary: #f8f8f8;
@white: #ffffff;
@whiteShade: #fcfcfc;
@whiteShade1: #fafafa;
@darkWhite: #ffffffb3;
@black: #000000;
@blackShade: #1b1b1b;
@blackShade1: #413e39;
@blackShade2: #292929;
@blackShade3: #030303;
@lightBlack: #353535;
@titleColor: #333333;
@borderBottom: #e2dddd;
@borderColor: #c7c7cc;
@borderColor1: #d4d4d4;
@borderColor3: #eeeeee;
@darkGray: #f3f3f3;
@lightdarkGray: #f6f6f6;
@lightdarkGray2: #ebebeb;
@lightGray: #949494;
@orange: #ff8719;
@veryLightGray: #d9d9d9;
@lightBlack: #4a4a4a;
@verylightBlack: #ababab;
@green: #10af46;
@lightgreen: #00aa6d;
@veryLightgreen: #ebfff8;
@red: #d60000;
@avatarTitle: #727272;
@analyticTitle: #767676;
@lightYellow: #ffea2e;
@aoiback: #f5f5f5;
@rootRowBack: #e0d9ff;
@roottableth: #3c3c3c;
@pink: #ff0c68;
@lightPink: #fff7fa;
@syntaxBlue: #2f80ed;
@lightBlue: #140fff;
@lightBlue1: #4d43c0;
@lightBlue3: #261e80cc;
@verylightblue: #f3f3ff;
@analyticBack: #fbfaff;
@dividerBack: #6f6f6f;
@ghostbtnBack: #e3e3e3;
@notification: #bdbdbd;
@subheader: #8f8f8f;
@btnTitle: #a4a4a4;
@btnLabel: #747474;
@greyShade: #828282;
@greyShade1: #848484;
@greyShade2: #878787;
@greyShade3: #9b9999;
@greyShade4: #636363;
@greyShade5: #808080;
@greyShade6: #b3b3b3;
@lightGreyShade: #5e5e5e;
@lightGreyShade1: #5c5c5c;
@lightWhite: #f9f9f9;
@lightWhite1: #f0f0f0;
@lightWhite2: #e0e0e0;
@lightWhite3: #dfdfdf;
@lightWhite4: #d7d7d7;
@lightWhite5: #c6c6c6;
@cream: #d6d6d6;
@cream1: #cacaca;
@cream3: #bfbfbf;
@cream4: #b4b4b4;
@cream5: #9a9a9a;
@typocolor: #696b72;
@greyShed: #42444a;
@lightred: #eb5757;

.flex-align-content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flexAlignCenter {
  display: flex;
  align-items: center;
}
.flexJustifyCenter {
  display: flex;
  justify-content: center;
}
.flexJustifyBetween {
  display: flex;
  justify-content: space-between;
}
.flexDirectionColumn {
  display: flex;
  flex-direction: column;
}
.flexJustifyAlignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.scrollbarW5 {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: @white;
  }
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 3px;
  }
}
.scrollbarW3 {
  &::-webkit-scrollbar {
    width: 3px;
    background-color: @white;
    height: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 3px;
  }
}
.noscrollbar {
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
  }
}

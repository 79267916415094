@import 'styles/variable.less';

.left-content {
  padding: 8px 0px 12px 0px;
  width: 288px;
  background-color: @white;
  .end-point-content {
    @media (max-width: 1440px) {
      height: auto;
    }
  }
  .start-point-content,
  .end-point-content,
  .start-end-point-content {
    margin-bottom: 10px;
    @media (max-width: 1440px) {
      height: auto;
    }
    .aoi-tab-select {
      width: 100%;
    }

    .start-point-btn {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }
  .or-content {
    font-weight: 500;
    font-size: 16px;
    color: @black;
    display: block;
    text-align: center;
    padding: 15px 0;
    border-top: 1px solid @cream;
  }
  .start-end-point-submit-btn {
    height: 22.5%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    @media (max-width: 1200px) {
      height: 13%;
    }
    @media (max-width: 1024px) {
      padding-bottom: 0px;
    }
    > button {
      width: 100%;
    }
  }
  .aoi-tab {
    height: 100%;
    .aoi-tab-content {
      height: 90%;
      margin-bottom: 10px;
      @media (max-width: 1024px) {
        height: 87%;
      }
      .aoi-title {
        padding-bottom: 5px;
        .aoi-title-inr {
          font-weight: 600;
          font-size: 14px;
          color: @titleColor;
          text-shadow: none;
        }
      }
      .radio-group-content {
        width: 100%;
      }
      .selected-aoi-file {
        margin-top: 7px;
      }
      > div:nth-child(2) {
        max-height: 84%;
        overflow-y: auto;
        width: 100% !important;
        &::-webkit-scrollbar {
          display: none !important;
        }
        @media (max-width: 1440px) {
          max-height: 81%;
        }
        @media (max-width: 1330px) {
          max-height: 78%;
        }
        @media (max-width: 1024px) {
          max-height: 70%;
        }
      }
      .aoi-select-submit-btn {
        padding-top: 20px;
        .aoi-tab-select {
          width: 100% !important;
          margin-bottom: 10px;
        }
        > button {
          width: 100%;
        }
        .aoi-upload-file {
          margin-bottom: 10px;
          > input {
            color: @primary;
          }
        }
      }
      .empty-aoi-list {
        padding-top: 0px;
      }
      .empty-route-list {
        padding-top: 0px;
      }
    }
  }
}
.aoicoord-filename {
  color: @primary;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: block;
  margin-top: 8px;
}
.aoicoord-upload {
  color: @primary;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  margin-top: 5px;
  margin-bottom: 15px;
  display: block;
}
.aoi-tab-submit-btn {
  display: flex;
  justify-content: space-between;
  > button {
    height: 36px;
    padding: 4px 26px;
  }
  .aoi-selection-mng {
    width: 100%;
  }
}
.approved-aoi {
  justify-content: flex-start;
  margin-top: 25px;
}

.decline-btn,
.approve-btn {
  width: auto !important;
  padding: 4px 23px;
  height: 34px;
}
.approve-btn {
  margin-left: 10px;
}
.start-inp {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  border: 0;
  background: @analyticBack;
  &:focus {
    border: 0;
  }
  > input {
    color: @primary;
    background: @analyticBack;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
}
.empty-point {
  font-size: 14px;
  color: @primary;
  background: @analyticBack;
  display: block;
  padding: 7px 0px 7px 10px;
  line-height: 17px;
}
.admin-approve {
  display: block;
  color: @primary;
}
.approve-dwnld {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  .approved {
    color: @primary;
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: normal !important;
  }
  .procurring {
    color: @primary;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: normal !important;
    margin-left: 10px;
  }
}
.declined {
  white-space: nowrap;
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: @red;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: normal !important;
}
.declined-aoi {
  .flex-align-content-center();
  justify-content: space-between;
  > div:first-child {
    .flex-align-content-center();
    justify-content: space-between;
  }
  > div:last-child {
    color: #2f80ed;
    cursor: pointer;
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: normal !important;
  }
}

.aoi-approve-modal,
.satsure-approve-modal {
  .aoi-approve-content,
  .satsure-approve-content {
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-flow: column;
    .notify-title,
    .notify-msg {
      font-weight: 500;
      color: @titleColor;
    }
    .notify-title {
      font-size: 24px;
      line-height: 29px;
    }
    .notify-msg {
      font-size: 20px;
      line-height: 24px;
    }
  }
}
.approved-points {
  justify-content: flex-start !important;
  height: 21% !important;
  padding-bottom: 0 !important;
}
.select-title {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
  color: @titleColor;
  text-shadow: none;
  letter-spacing: 0.5px;
  > span {
    color: @red;
    padding-left: 2px;
  }
}
.complete-aoi {
  height: 100% !important;
}

@primary-color: #316DB3;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;@btn-border-width: 0.5px;
@import 'styles/variable.less';

.mainTimelineContent {
  background-color: @white;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: @veryLightGray;
    border-radius: 3px;
  }
  .mainTableTLCol {
    height: 100%;
    padding: 17px;
    @media (max-width: 1441px) {
      padding: 15px;
    }
    .mainProgressBar {
      background: @white;
      border: 1px solid @greyShade6;
      border-radius: 2px;
      padding: 15px 20px 8px 20px;

      > div {
        display: flex;
        justify-content: space-between;
        padding-bottom: 5px;
        font-weight: 500;
        font-size: 16px;

        > span {
          color: @lightBlack;
        }

        > div {
          color: @orange;
          span:first-child {
            // padding-right: 50px;
          }
        }
      }
      .close-project-btn {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        > button {
          width: 160px;
        }
      }
    }
    .proposed-timeline {
      margin-top: 15px;
      background-color: @darkGray;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 15px;
      > span {
        font-weight: 500;
        font-size: 16px;
        color: @lightBlack;
        letter-spacing: 0.5px;
      }
    }
    .mainTimeLine {
      color: @lightBlack;
      margin-top: 9px;
      > div {
        height: 100%;
        > div {
          height: 100%;
          > div {
            height: 100%;
          }
        }
        .timelineTable {
          .ant-table-body {
            margin-top: 12px;
          }
          height: 100%;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 5px;
          }
          &::-webkit-scrollbar-thumb {
            background: @veryLightGray;
            border-radius: 3px;
          }
          table {
            thead {
              tr {
                th {
                  border-bottom: 2px solid @lightGray;
                  background-color: @white;
                  color: @verylightBlack;
                  font-weight: 500;
                  font-size: 15px;
                  padding: 0px 11px 7px 0px;
                  &::before {
                    width: 0 !important;
                  }
                }
              }
            }
            tbody {
              tr {
                &:not(:last-child) {
                  td {
                    border-bottom: 0.5px solid @lightWhite2;
                  }
                }
                td {
                  background-color: @white;
                  border-bottom: transparent;
                  padding: 0px;
                  &:hover {
                    background-color: @white;
                  }
                  &:first-child {
                    padding-left: 0;
                  }
                  &:first-child,
                  &:nth-child(2) {
                    border: 0;
                  }
                  .projectStage {
                    color: @cream5;
                    font-weight: 500;
                    font-size: 14px;
                  }
                  .complete-stage {
                    color: @lightBlack !important;
                  }
                  .ongoing-stage {
                    color: @black !important;
                    text-decoration: underline;
                  }
                  .started-stage {
                    text-decoration: none;
                  }
                  &:last-child {
                    padding-left: 0;
                  }
                  &:nth-child(4) {
                    padding-right: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .addtimeline-btn {
      position: absolute;
      top: 50%;
      left: 45%;
    }
  }
}

.closed-project-main {
  width: 100%;
  height: 122px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  .closed-project-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: @titleColor;
  }
  .closed-project-buttons {
    .closed-project-btn {
      height: 36px;
      padding: 4px 39.5px;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }
    .closed-project-confirm {
      margin-right: 20px;
    }
    .closed-project-cancel {
      background-color: @ghostbtnBack;
    }
  }
}

.mainTeamMemberCol {
  border: 1px solid @lightWhite3;
  padding: 0 !important;
  height: 100%;

  .mainMemberContent {
    height: 24%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: @veryLightGray;
      border-radius: 3px;
    }
    @media (max-width: 1200px) {
      height: unset;
    }
    > div {
      background-color: @white;
      position: sticky;
      top: 0;
      z-index: 999;

      &:nth-child(2) {
        padding: 20px 20px 0px 20px !important;
        position: relative;
        z-index: 1;
        @media (max-width: 1330px) {
          padding: 10px 20px 0px 20px !important;
        }
      }

      > div {
        > div {
          font-weight: 500;
          font-size: 18px;
          color: @btnTitle;
        }
      }
    }

    .memberAvatar {
      border: 1px solid @lightWhite3;
      background-color: @white;
      margin: 0 10px 10px 0;
      cursor: pointer;
      color: @avatarTitle;
      height: 32px;
      width: 32px;
      &:hover {
        background-color: @avatarTitle;
        color: @white;
      }
      @media (max-width: 1330px) {
        margin: 0 5px 5px 0;
      }
      span {
        border: unset;
        font-size: 12px;
      }
    }
    .project-member-add-icon {
      > svg {
        padding-bottom: 1px;
      }
    }
    .changecolor {
      border: 1px solid @lightWhite3;
      background-color: @primary;
      margin: 0 10px 10px 0;
      cursor: pointer;
      color: @white;
      @media (max-width: 1330px) {
        margin: 0 5px 5px 0;
      }
      span {
        border: unset;
        font-size: 12px;
        line-height: 30px;
      }
    }
  }

  .mainActivityContent {
    height: calc(100% - 24%);
    border-bottom: none;
    @media (max-width: 1024px) {
      height: unset;
    }
    > div {
      &:nth-child(2) {
        padding: 7px 2px 7px 7px !important;
        height: 88%;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: @veryLightGray;
          border-radius: 3px;
        }
        @media (max-width: 1440px) {
          height: 86%;
        }
        @media (max-width: 1330px) {
          height: 84%;
        }
        @media (max-width: 1200px) {
          height: unset;
        }
      }
      @media (max-width: 1025px) {
        &:nth-child(2) {
          padding: 20px !important;
        }
      }
      > div {
        > div:first-child {
          font-weight: 500;
          font-size: 18px;
          color: @btnTitle;
        }
        > div:last-child {
          padding: 0;
        }

        > div:nth-child(2) {
          span {
            font-weight: 500;
            font-size: 12px;
            color: @avatarTitle;
          }
        }
      }
      .avtarchangecolor {
        border: 1px solid @lightWhite3;
        background-color: @primary;
        cursor: pointer;
        span {
          color: @white !important;
          border: unset;
        }
      }
    }

    .listMemberActivity {
      &:nth-child(2) {
        padding: 10px !important;
      }

      .memberListContent {
        background: @white;
        border: 0.5px solid @lightWhite2;
        border-radius: 2px;
        padding: 9px;
        margin-bottom: 5px;
        display: flex;
        align-items: flex-start;
        .activity-time {
          display: block;
          margin-top: 5px;
          font-weight: 400;
          font-size: 12px;
          color: @lightWhite5;
          padding-bottom: 13px;
        }

        .memberListItem {
          > div:last-child {
            margin-top: 5px;
            > h4 {
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              color: @lightBlack;
            }
            > div {
              font-weight: 300;
              font-size: 12px;
              line-height: 14px;
              color: @lightBlack;
              word-break: break-all;
            }
          }
          .itemAvatar {
            height: 28px;
            width: 28px;
            border: 0.5px solid @avatarTitle;
            background-color: @white;
            span {
              color: @avatarTitle;
              border: unset;
              font-size: 12px;
              line-height: 27px;
            }
          }
        }
      }
    }
  }
}

.emptyColum {
  position: relative;
  display: flex;
  justify-content: center;

  .roundContent {
    height: 12px;
    width: 12px;
    background-color: @orange;
    border-radius: 50%;
    z-index: 2;
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 12px;
      width: 1px;
      height: 400%;
      z-index: 1;
      border-left: 1px dashed #cacaca;
      &:last-child {
        height: 0%;
      }
    }
  }

  .completeContent {
    background-color: @green;
    &::after {
      border-left: 1px solid #cacaca !important;
    }
  }
  .completePendingCon {
    background-color: @cream1;
  }
  .notStartedPendingCon {
    background-color: @red;
  }
  .row-empty-content {
    &::after {
      height: 0px;
    }
  }
}

.approvalStatus {
  color: @green;
  font-weight: 500;
  font-size: 14px;
}

.in_progress {
  color: @orange;
}
.waiting-approve-mng {
  font-weight: 400;
  font-size: 14px;
  color: @avatarTitle;
}
.team_memeber_list {
  display: flex;
  flex-wrap: wrap;
  .last-div {
    cursor: pointer;
    width: 28px;
    height: 28px;
  }
}
.scheduleColumn {
  display: block;
  font-weight: 400;
  font-size: 12px;
  margin-right: 11px;
  @media (max-width: 1025px) {
    margin-right: 7px;
  }
}
.completeDelayContent {
  font-size: 14px;
  color: @green;
  font-weight: 500;
}
.inProgressDelayCon {
  font-size: 14px;
  color: @orange;
  font-weight: 500;
}
.pendingDelayCon {
  font-size: 14px;
  color: @red;
  font-weight: 500;
}
.tootltipMember {
  > div {
    > div {
      color: @black;
    }
  }
}
.ant-table-sticky-scroll-bar {
  height: 0 !important;
}
.empty-timeline {
  position: absolute;
  top: 40%;
  left: 43%;
  font-weight: 500;
  font-size: 18px;
  color: @greyShade;
}
.in-progress {
  color: @orange;
}
.not-started {
  color: @red;
}
.completed {
  color: @green;
}
.closed {
  color: @primary;
}
.mainTimeLine {
  height: calc(100% - 166px);
}
.mainRowContent {
  height: 60px;
}
.status-title {
  > span:last-child {
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 14px;
    color: @primary;
  }
}
.change-status-dd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.change-status-title {
  color: @notification;
  margin-right: 5px;
}
.project-status-dd {
  background-color: @white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  width: 118px;
  padding: 7px 10px;
  margin-top: 5px;

  > div {
    gap: 7px;
    > div {
      > label {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: @primary;
        > span {
          > span {
            height: 11px;
            width: 11px;
            &::after {
              top: 85.6%;
              left: 85.4%;
              height: 9px;
              width: 9px;
              transition: none;
            }
          }
        }
      }
    }
    > div:first-child {
      border-bottom: 1px solid @lightWhite2;
      width: 116%;
      > label {
        padding-bottom: 7px;
      }
    }
  }
}
.status-change-clr {
  color: @primary;
}

@primary-color: #316DB3;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;@btn-border-width: 0.5px;
@import 'styles/variable.less';

.main-title-content {
  .project-name-list {
    width: 192px;
    .ant-select-selector {
      border: 1px solid @primary;
      box-shadow: none !important;
      .ant-select-selection-placeholder {
        color: @primary;
      }
      .ant-select-selection-item {
        font-weight: 500;
        font-size: 14px;
      }
    }
    .ant-select-arrow {
      > span {
        > svg {
          fill: @primary;
        }
      }
    }
  }
}
.main-title-map-view {
  padding: 10px 24px;
}
.ant-select-item-option {
  padding: 7px 20px;
  > div {
    color: @greyShed;
    font-size: 16px;
    font-weight: 500;
  }
  &.ant-select-item-option-selected {
    background-color: @primary !important;
    > div {
      color: @white;
    }
  }
}

.main-map-view {
  height: calc(100vh - 64px);
  overflow-y: auto;
  border: 1px solid @white;
  border-radius: 4px;
  .main-title-content {
    height: 72px;
    .ant-page-header-heading {
      .ant-page-header-heading-left,
      .ant-page-header-heading-title {
        overflow: unset;
      }
      .ant-page-header-heading-title {
        width: 380px;
        display: flex;
        gap: 18px;
        > input {
          width: 268px;
        }
      }
    }
    > div {
      > div {
        > div {
          margin-right: 10px;
          font-size: 8px;
        }
        > span {
          color: @titleColor;
        }
      }
    }
  }
}
.project-popup {
  width: 300px;
  background-color: @white;
  border-radius: 2px;
  .pop-up-info {
    width: 100%;
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      flex-direction: column;
      font-weight: 500;
      font-size: 14px;
      > div:first-child {
        color: @greyShade;
      }
      > div:last-child {
        padding-left: 5px;
        color: @black;
      }
    }
  }
  #closebutton,
  #routeclosebutton {
    font-size: 16px;
    border: 0;
    background: white;
  }
}

@primary-color: #316DB3;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;@btn-border-width: 0.5px;
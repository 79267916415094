@import 'styles/variable.less';
@import 'styles/image.less';

.mainRow {
  background-image: url(@login-background);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  min-height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  .logoContent {
    position: absolute;
    top: 50px;
    left: 116px;
    width: 190px;
    height: 170px;
    object-fit: 'contain';
  }

  .textBetweenLogo {
    font-family: Righteous;
    position: absolute;
    top: 46%;
    left: 47%;
    transform: translate(-50%, -50%);
    color: @primary;
    font-size: 40px;
    font-weight: 600;
  }
  .formContent {
    margin-right: 100px;
    .mainLoginCard {
      padding: 90px 35px 50px 35px !important;
      filter: drop-shadow(0px 0px 10px rgba(3, 3, 3, 0.25));
      border-radius: 10px;
      width: 350px;
      @media (max-width: 768px) {
        padding-top: 70px !important;
      }
      .forgotPasswordBtn {
        display: flex;
        justify-content: center;
      }
      .mainLoginForm {
        .formTitle {
          font-weight: 600;
          color: @primary;
          width: 320px;
        }
        > .inputField {
          margin-top: 30px;
          > div {
            > div {
              > label {
                color: @primary !important;
              }
            }
          }
          .inputLabel:focus {
            background-color: transparent !important;
            border: 0 !important;
            border-bottom: 1px solid @borderBottom !important;
            box-shadow: none !important;
            color: @primary;
            font-weight: 500;
            > input:focus {
              background-color: transparent !important;
              border: 0 !important;
              box-shadow: none !important;
              color: @primary;
              font-weight: 500;
            }
          }
          .inputLabel {
            border: 0;
            border-bottom: 1px solid @borderBottom !important;
            color: @primary;
            > input {
              color: @primary;
            }
          }
        }
        .ant-btn {
          margin-top: 25px;
        }
      }
      > div {
        padding: 0 !important;
        > p {
          padding-top: 10px !important;
          text-align: center;
          font-weight: 600;
          color: @primary;
        }
        .formBottomLogo {
          text-align: center;
          margin-top: 130px;
          @media (max-width: 768px) {
            margin-top: 40px;
          }
          > p {
            color: @primary;
            margin: 0;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@primary-color: #316DB3;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;@btn-border-width: 0.5px;
@font-face {
  font-family: 'ManropeBold';
  src: local('ManropeBold'), url('./fonts/ttf/ManropeBold.ttf') format('truetype');
}
@font-face {
  font-family: 'ManropeExtraBold';
  src: local('ManropeExtraBold'), url('./fonts/ttf/ManropeExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'ManropeExtraLight';
  src: local('ManropeExtraLight'), url('./fonts/ttf/ManropeExtraLight.ttf') format('truetype');
}
@font-face {
  font-family: 'ManropeLight';
  src: local('ManropeLight'), url('./fonts/ttf/ManropeLight.ttf') format('truetype');
}
@font-face {
  font-family: 'ManropeMedium';
  src: local('ManropeMedium'), url('./fonts/ttf/ManropeMedium.ttf') format('truetype');
}
@font-face {
  font-family: 'ManropeRegular';
  src: local('ManropeRegular'), url('./fonts/ttf/ManropeRegular.ttf') format('truetype');
}
@font-face {
  font-family: 'ManropeSemiBold';
  src: local('ManropeSemiBold'), url('./fonts/ttf/ManropeSemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'ManropeVariableFontWeight';
  src: local('ManropeVariableFontWeight'),
    url('./fonts/ttf/ManropeVariableFontWeight.ttf') format('truetype');
}

:root {
  --white: #ffffff;
  --black: #000;
  --primary: #316db3;
  --secondary: #42444a;
  --typocolor: #696b72;
  --MNRPLR: 'Manrope';
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  font-family: 'ManropeRegular' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5 !important;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; */
  font-family: 'ManropeRegular' !important;
}

.ol-popup {
  padding: 15px;
  border-radius: 4px;
  bottom: 15px;
}
.ol-popup-closer {
  display: none;
}
.ol-popup:has(.point-btn-div) {
  background-color: #252525f2;
  padding: 0px !important;
  border: 0;
  left: 0px;
  bottom: unset;
  top: 5px;
}
.ol-popup:has(.point-btn-div)::after,
.ol-popup:has(.point-btn-div)::before {
  display: none !important;
}
.ol-popup:has(.point-btn-div) .ol-popup-content {
  min-width: 78px !important;
  .point-btn-div {
    display: flex;
    align-items: center;
    justify-content: space-evenly !important;
    > button {
      width: 28px !important;
      height: 26px !important;
      background-color: unset !important;
      border: 0 !important;
      color: #ffffff !important;
      padding: 0 !important;
      cursor: pointer !important;
    }
  }
}
.ol-popup:has(.project-popup) {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px !important;
  border: 1px solid #ccc;
  bottom: 22px !important;
  left: -38px;
  min-width: 200px !important;
}

@import 'styles/variable.less';

.modalFormContent {
  > p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: @primary;
  }
  > .project-detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > h1 {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
    > div {
      cursor: pointer;
    }
  }
  .addmember-form {
    background: @white;
    border: 1px solid @primary;
    border-radius: 4px;
    padding: 40px 44px 20px 44px;

    .formField {
      label {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: @titleColor;
        > span {
          > span {
            color: @red;
            font-size: 18px;
            padding-left: 2px;
          }
        }
      }

      .formInputField {
        padding: 8px;
        background: @white;
        color: @primary;
        font-weight: 500;
        height: 36px;
        font-size: 14px;
      }

      .formSelectField {
        > div {
          background: @white;
          font-size: 14px;
          height: 36px;
          display: flex;
          align-items: center;
          > div {
            visibility: hidden !important;
            height: 26px;

            &:before {
              visibility: visible !important;
              content: 'Search members' !important;
              color: @cream3;
              padding: 3px 0 0 8px;
            }
          }
        }
      }
    }
    .selected-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 5px;
      .member-list {
        border: 1px solid @primary;
        padding: 3px 8px 3px 15px;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        margin-right: 20px;
        margin-bottom: 12px;
        > span {
          margin-right: 16px;
          color: @primary;
        }
      }
    }
    .form-select-content {
      margin-bottom: 5px;
    }

    .formButton {
      padding-top: 40px;
      text-align: end;

      .formCancelbtn,
      .formCreatebtn {
        padding: 0 25px;
      }

      .formCreatebtn {
        margin-right: 20px !important;
      }
    }
  }
  .addproject-form {
    .ant-form-item-required::before {
      display: none !important;
    }
    label {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      > span {
        color: @btnTitle;
        > span {
          color: @red;
          font-size: 12px;
        }
      }
    }
  }
}

.addproject-form {
  .formButton {
    padding-top: 40px;
    text-align: end;

    .formCancelbtn,
    .formCreatebtn {
      padding: 0 25px;
    }

    .formCreatebtn {
      margin-left: 20px !important;
    }
  }
  .bid-submission {
    .ant-picker-input {
      input {
        color: @primary;
      }
    }
  }
}

.member-dropdown {
  &.ant-select-item-option {
    &.ant-select-item-option-selected {
      > div {
        > label {
          > span:last-child {
            color: @primary !important;
            font-weight: 500;
            font-size: 18px;
          }
        }
      }
    }
  }

  &.ant-select-item-option {
    &.ant-select-item-option-selected {
      background-color: @white !important;
      z-index: 9999;
    }
    > div {
      > label {
        width: 100%;
        .ant-checkbox-checked .ant-checkbox-inner {
          border-color: @primary !important;
        }
        > span {
          .ant-checkbox-inner {
            border: 1px solid #42444a;
          }
          &:last-child {
            color: @greyShed !important;
            font-weight: 500;
            font-size: 18px;
          }
        }
      }
    }
    .ant-select-item-option-state {
      display: none !important;
    }
  }
}

@primary-color: #316DB3;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;@btn-border-width: 0.5px;